@screen xs {
  .bg-gentux {
    background-image: linear-gradient(to right, theme('colors.gray.lighter') 50%, white 0);
  }
}

.bg-menguin {
  background-size: 3000px auto, auto auto;
  background-position: center top;
  background-repeat: no-repeat;
  background-color: theme('colors.gray.light');
  background-image: linear-gradient(164deg, transparent 560px, white 0);
}

@screen xs {
  .bg-menguin {
    background-size: auto auto, 3000px auto, auto auto;
    background-image: linear-gradient(to right, transparent 50%, white 0),
      linear-gradient(164deg, transparent 600px, theme('colors.gray.lighter') 0),
      linear-gradient(to right, theme('colors.gray.light') 50%, white 0); 
  }
}

@screen sm {
  .bg-menguin {
    background-image: linear-gradient(to right, transparent 50%, white 0),
      linear-gradient(164deg, transparent 700px, theme('colors.gray.lighter') 0),
      linear-gradient(to right, theme('colors.gray.light') 50%, white 0);
  }
}