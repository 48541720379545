.grid-mobile {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    max-width: 100%;
  }

  .grid-mobile .thumbnail-wrap {
    max-width: 100%;
    width: 60px;
  }

  .grid-mobile .thumbnail-wrap,
  .grid-mobile svg {
    height: 60px;
  }

  .grid-mobile > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    transition: background-color 0.2s ease, transform 0.1s ease;
    cursor: pointer;
    @apply border border-gray;
  }

  .grid-mobile > div:active {
    transform: scale(0.9);
  }

  .grid-mobile > div .banner {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
    z-index: auto;
  }

  .grid-mobile > div .banner .banner-label {
    background-color: transparent;
    padding: 4px;
    white-space: normal;
  }

  .grid-mobile > div .thumbnail-wrap,
  .grid-mobile > div svg {
    flex-grow: 1;
  }

  .preview-exploded {
    width: calc(100vw);
    max-width: 1600px;
    position: relative;
    overflow: hidden;
  }

  .preview-exploded img {
    opacity: 1 !important;
  }

  .preview-exploded  img[class*='shadows'] {
    visibility: hidden;
  }

  .preview-exploded > div {
    position: relative;
    will-change: width;
    transform: translateZ(0);
  }

  .preview-exploded [class*='exploded-layer-wrap'] {
    pointer-events: none;
    width: 30vw;
    max-width: 457px;
    will-change: left;
    transform: translateZ(0);
    position: absolute;
    top: 0;
  }

  .preview-exploded [class*='exploded-layer-wrap'] img:first-child {
    position: relative;
  }

  .preview-exploded [class*='exploded-layer-wrap']:hover {
    z-index: 1000;
  }
  
  .preview-exploded .exploded-layer-wrap-placeholder {
    position: relative; /* need to set a layer that doesn't change position to relative to keep the parent height from collapsing */
    visibility: hidden;
    pointer-events: none;
  }

  .preview-exploded .exploded-image-wrap,
  .preview-exploded .exploded-image-wrap-empty {
    transition: all 0.2s ease;
  }

  .preview-exploded .exploded-image-wrap-empty {
    opacity: 0.6;
  }

  .preview-exploded .hit-target {
    position: absolute;
    z-index: 500;
    pointer-events: auto;
    background: rgba(255, 0, 0, 0);
    display: grid;
    place-items: center;
    cursor: pointer;
    transition: all 0.2s ease;
    /* background: rgba(255,0,0,.1); */
  }

  .preview-exploded .hit-target:active ~ .exploded-image-wrap,
  .preview-exploded .hit-target:active ~ .exploded-image-wrap-empty {
    transform: scale(0.96);
  }

  .preview-exploded .banner {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate3d(-50%, -100%, 0);
    transition: all 0.2s ease;
    z-index: -100;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .preview-exploded .banner .banner-label {
    transition: all 0.2s ease;
    @apply bg-gray-light text-gray-darker border-b border-b-brand-darker;
    padding: 8px 8px;
    min-width: 32px;
  }

  .preview-exploded .banner:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    @apply border-t-[5px] border-t-brand-darker;
  }

  .preview-exploded .exploded-layer-wrap-long-tie,
  .preview-exploded .exploded-layer-wrap-tie,
  .preview-exploded .exploded-layer-wrap-tie-empty {
    left: -3%;
    transform: translateY(9%);
  }

  .preview-exploded .exploded-layer-wrap-long-tie .hit-target,
  .preview-exploded .exploded-layer-wrap-tie .hit-target,
  .preview-exploded .exploded-layer-wrap-tie-empty .hit-target {
    top: 4%;
    left: 41%;
    right: 40%;
    bottom: 45%;
  }

  .preview-exploded .exploded-layer-wrap-long-tie .exploded-image-wrap,
  .preview-exploded .exploded-layer-wrap-long-tie .exploded-image-wrap-empty,
  .preview-exploded .exploded-layer-wrap-tie .exploded-image-wrap,
  .preview-exploded .exploded-layer-wrap-tie .exploded-image-wrap-empty,
  .preview-exploded .exploded-layer-wrap-tie-empty .exploded-image-wrap,
  .preview-exploded .exploded-layer-wrap-tie-empty .exploded-image-wrap {
    transform-origin: center 10%;
  }

  .preview-exploded .exploded-layer-wrap-bow-tie {
    left: -3%;
    transform: translateY(9%);
  }

  .preview-exploded .exploded-layer-wrap-bow-tie .hit-target {
    top: 4%;
    left: 41%;
    right: 40%;
    bottom: 86%;
  }

  .preview-exploded .exploded-layer-wrap-bow-tie .exploded-image-wrap,
  .preview-exploded .exploded-layer-wrap-bow-tie .exploded-image-wrap-empty {
    transform-origin: center 10%;
  }

  .preview-exploded .exploded-layer-wrap-shirt,
  .preview-exploded .exploded-layer-wrap-shirt-empty {
    left: 22%;
  }

  .preview-exploded .exploded-layer-wrap-shirt img[class*='shadows'],
  .preview-exploded .exploded-layer-wrap-shirt-empty img[class*='shadows'] {
    visibility: visible;
  }

  .preview-exploded .exploded-layer-wrap-shirt .hit-target,
  .preview-exploded .exploded-layer-wrap-shirt-empty .hit-target {
    top: 0%;
    left: 15%;
    right: 15%;
    bottom: 28%;
  }

  .preview-exploded .exploded-layer-wrap-shirt .exploded-image-wrap,
  .preview-exploded .exploded-layer-wrap-shirt .exploded-image-wrap-empty,
  .preview-exploded .exploded-layer-wrap-shirt-empty .exploded-image-wrap,
  .preview-exploded .exploded-layer-wrap-shirt-empty .exploded-image-wrap-empty {
    transform-origin: center 20%;
  }

  .preview-exploded .exploded-layer-wrap-pocket-square {
    left: 0%;
    transform: translateY(15%);
  }

  .preview-exploded .exploded-layer-wrap-pocket-square .hit-target {
    top: 20%;
    left: 58%;
    right: 30%;
    bottom: 72%;
  }

  .preview-exploded .exploded-layer-wrap-pocket-square [class*='shadow'] {
    display: none;
  }

  .preview-exploded .exploded-layer-wrap-pocket_square-empty {
    left: 0%;
    transform: translateY(15%);
  }

  .preview-exploded .exploded-layer-wrap-pocket_square-empty .hit-target {
    top: 20%;
    left: 58%;
    right: 30%;
    bottom: 68%;
  }

  .preview-exploded .exploded-layer-wrap-pocket_square-empty [class*='shadow'] {
    display: none;
  }

  .exploded-layer-wrap-pocket-square .exploded-image-wrap,
  .exploded-layer-wrap-pocket-square .exploded-image-wrap-empty,
  .exploded-layer-wrap-pocket_square-empty .exploded-image-wrap,
  .exploded-layer-wrap-pocket_square-empty .exploded-image-wrap-empty {
    transform-origin: 65% 20%;
  }

  .exploded-layer-wrap-lapel-pin,
  .exploded-layer-wrap-lapel_pin-empty {
    left: 1%;
  }

  .exploded-layer-wrap-lapel-pin .hit-target,
  .exploded-layer-wrap-lapel_pin-empty .hit-target {
    top: 13%;
    left: 58%;
    right: 35%;
    bottom: 82%;
  }

  .exploded-layer-wrap-lapel-pin .exploded-image-wrap,
  .exploded-layer-wrap-lapel-pin .exploded-image-wrap-empty,
  .exploded-layer-wrap-lapel_pin-empty .exploded-image-wrap,
  .exploded-layer-wrap-lapel_pin-empty .exploded-image-wrap-empty {
    transform-origin: 60% 10%;
  }

  .exploded-layer-wrap-jacket,
  .exploded-layer-wrap-pants,
  .exploded-layer-wrap-jacket-empty {
    right: -5%;
  }

  .exploded-layer-wrap-jacket .hit-target,
  .exploded-layer-wrap-pants .hit-target,
  .exploded-layer-wrap-jacket-empty .hit-target {
    top: 0%;
    left: 5%;
    right: 5%;
    bottom: 25%;
  }

  .exploded-layer-wrap-jacket .exploded-image-wrap,
  .exploded-layer-wrap-jacket .exploded-image-wrap-empty,
  .exploded-layer-wrap-pants .exploded-image-wrap,
  .exploded-layer-wrap-pants .exploded-image-wrap-empty,
  .exploded-layer-wrap-jacket-empty .exploded-image-wrap,
  .exploded-layer-wrap-jacket-empty .exploded-image-wrap-empty {
    transform-origin: center 20%;
  }

  .exploded-layer-wrap-pants .banner {
    display: none;
  }

  .exploded-layer-wrap-jacket {
    z-index: 10;
  }

  .exploded-layer-wrap-vest,
  .exploded-layer-wrap-vest-empty,
  .exploded-layer-wrap-cummerbund {
    left: 45%;
  }

  .exploded-layer-wrap-vest .hit-target,
  .exploded-layer-wrap-vest-empty .hit-target,
  .exploded-layer-wrap-cummerbund .hit-target {
    top: 0%;
    left: 30%;
    right: 30%;
    bottom: 38%;
  }

  .exploded-layer-wrap-vest .exploded-image-wrap,
  .exploded-layer-wrap-vest .exploded-image-wrap-empty,
  .exploded-layer-wrap-vest-empty .exploded-image-wrap,
  .exploded-layer-wrap-vest-empty .exploded-image-wrap-empty {
    transform-origin: center 20%;
  }

  .exploded-layer-wrap-cummerbund .exploded-image-wrap,
  .exploded-layer-wrap-cummerbund .exploded-image-wrap-empty {
    transform-origin: center 50%;
  }

  .exploded-layer-wrap-socks img[class*='hanger'],
  .exploded-layer-wrap-socks-empty img[class*='hanger'] {
    display: none;
  }

  .exploded-layer-wrap-socks .hit-target,
  .exploded-layer-wrap-socks-empty .hit-target {
    top: 13%;
    left: 8%;
    right: 78%;
    bottom: 70%;
  }

  .exploded-layer-wrap-socks .exploded-image-wrap,
  .exploded-layer-wrap-socks .exploded-image-wrap-empty,
  .exploded-layer-wrap-socks-empty .exploded-image-wrap,
  .exploded-layer-wrap-socks-empty .exploded-image-wrap-empty {
    transform-origin: 20% 10%;
  }

  .exploded-layer-wrap-shoe .hit-target,
  .exploded-layer-wrap-shoe-empty .hit-target {
    top: 78%;
    left: 6%;
    right: 58%;
    bottom: 6%;
  }

  .exploded-layer-wrap-shoe .exploded-image-wrap,
  .exploded-layer-wrap-shoe .exploded-image-wrap-empty,
  .exploded-layer-wrap-shoe-empty .exploded-image-wrap,
  .exploded-layer-wrap-shoe-empty .exploded-image-wrap-empty {
    transform-origin: 20% 80%;
  }

  .exploded-layer-wrap-belt .hit-target,
  .exploded-layer-wrap-belt-empty .hit-target,
  .exploded-layer-wrap-suspenders .hit-target {
    top: 82%;
    left: 49%;
    right: 29%;
    bottom: 7%;
  }


  .exploded-layer-wrap-belt .exploded-image-wrap,
  .exploded-layer-wrap-belt .exploded-image-wrap-empty,
  .exploded-layer-wrap-belt-empty .exploded-image-wrap,
  .exploded-layer-wrap-belt-empty .exploded-image-wrap-empty,
  .exploded-layer-wrap-suspenders .exploded-image-wrap,
  .exploded-layer-wrap-suspenders .exploded-image-wrap-empty {
    transform-origin: 60% 80%;
  }

  .exploded-layer-wrap-cufflinks .hit-target,
  .exploded-layer-wrap-cufflinks-empty .hit-target {
    top: 82%;
    left: 75%;
    right: 8%;
    bottom: 7%;
  }

  .exploded-layer-wrap-cufflinks .exploded-image-wrap,
  .exploded-layer-wrap-cufflinks .exploded-image-wrap-empty,
  .exploded-layer-wrap-cufflinks-empty .exploded-image-wrap,
  .exploded-layer-wrap-cufflinks-empty .exploded-image-wrap-empty {
      transform-origin: 80% 80%;
  }

  @media screen(lg) { 
    .preview-exploded .banner .banner-label {
      white-space: nowrap;
    }
  }
  
  @media screen(xl) { 
    .sticky-box {
      margin-top: 0;
      bottom: 2vh;
    }
  }

  @media (hover: hover) {
    .grid-mobile > div:hover {
      @apply bg-white;
    }

    .preview-exploded .hit-target:hover ~ .exploded-image-wrap {
      filter: brightness(110%);
    }

    .preview-exploded .hit-target:hover ~ .exploded-image-wrap-empty {
      opacity: 1;
    }

    .preview-exploded .hit-target:hover .banner-label {
      @apply bg-white;
    }
  }
