.btn-icon {
  -text-decoration: none;
  align-items: center;
  column-gap: 4px;
  cursor: pointer;
  display: inline-flex;
  font-family: Druk Wide Web, Arial Black, sans-serif;
  font-size: 12px;
  justify-content: center;
  letter-spacing: 0.63px;
  line-height: 1.5;
  line-height: 1rem;
  padding: 16px 24px;
  text-align: center;
  transition-duration: 150ms;
  transition-property: all;
  white-space: nowrap;
}

.btn-icon:hover {
  color: #000000;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

.btn-icon:hover > svg {
  color: #000000;
}

.btn-icon:disabled {
  cursor: not-allowed;
  opacity: 0.2;
  box-shadow: none;
}