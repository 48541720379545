.swatch {
  backface-visibility: hidden;
}

.fabric {
  mask-image: url(https://gentux.imgix.net/1604575069_swatchmask.png);
  mask-size: cover;
  backface-visibility: hidden;
}

