.modal::before {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  content: ' ';
  z-index: 25;
  transition: all;
  transition-delay: 1000;
  transition-duration: 100;
}